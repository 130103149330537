body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container input {
	width: 100%;
	padding: 10px;
	margin-bottom: 8px;
	box-sizing: border-box;
	border-radius: 2px;
	border: solid 1px #c0c3cb;
	font-family: inherit;
	font-size: 15px;
}

@media print {
	body {
		zoom: 5;
	}
	.hideOnPrint {
		display: none;
	}
	.printableContainer {
		max-width: 100% !important;
	}
}
